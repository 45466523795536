import { FC, useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { IApplicationMicroserviceComputeBound } from 'types/application/microservice/IApplicationMicroservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetApplicationMicroservicePodsQuery, useLazyGetLogsQuery } from 'api/applicationMicroserviceSlice';
import IPod from 'types/application/microservice/IPod';

const LogsTab: FC<{
  microservice: IApplicationMicroserviceComputeBound;
}> = ({ microservice }) => {
  const [selectedPod, setSelectedPod] = useState<string>('');
  const logBody = useRef<HTMLDivElement>(null);
  const [streamLogs, result] = useLazyGetLogsQuery();
  const { data: pods, error: podsError, isLoading: podsLoading } = useGetApplicationMicroservicePodsQuery({ microserviceId: microservice.id });

  useEffect(() => {
    if (logBody && logBody.current) {
      // scroll bottom of logs body into view as it expands
      logBody.current.scrollIntoView();

      // keep logs scrolled to bottom as new messages come in
      const scrollHeight = logBody.current?.scrollHeight;
      logBody.current.scrollTop = scrollHeight;
    }
  }, [result]);

  useEffect(() => {
    if (selectedPod) {
      streamLogs({ namespace: microservice.namespace, podName: selectedPod, applicationId: microservice.application?.id });
    }
  }, [selectedPod]);

  const prettifyName = (podName: string): string => {
    const chunks = podName.split('-');
    const env = chunks[0];
    const deploymentId = chunks[chunks.length - 2];
    const podId = chunks[chunks.length - 1];

    return `${env} ${deploymentId}-${podId}`;
  };

  if (podsLoading) return <Spinner />;
  if (podsError) return <Error />;

  return (
    <Container className="mb-4">
      {
        pods && pods.length > 0 ? (
          <>
            <Row>
              <Col md={6}>
                <DropdownButton
                  variant="secondary"
                  menuVariant="dark"
                  title={selectedPod ? prettifyName(selectedPod) : translate('selectAPod')}
                  className="d-inline-block"
                  onSelect={(e) => setSelectedPod(e as string)}
                >
                  {
                    pods.map((pod: IPod) => (
                      <Dropdown.Item
                        key={pod.name}
                        eventKey={pod.name}
                        active={selectedPod === pod.name}
                      >
                        {prettifyName(pod.name)}
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                {
                  selectedPod ? (
                    <>
                      <Row className="my-2">
                        <Col>
                          <FontAwesomeIcon className="text-warning" icon="info-circle" />
                          <span className="text-warning ms-2">{translate('logsFromStdOutAndStdErr')}</span>
                        </Col>
                      </Row>
                      <Card bg="dark" style={{ minHeight: '25rem', maxHeight: '35rem' }}>
                        <Card.Body style={{ overflowY: 'scroll' }} ref={logBody}>
                          <pre>
                            {
                              result.data && result.data.map((log: string, index: number) => {
                                if (log.includes("[info]")) return <></>;
                                const chunks = log.split(' ');
                                const formatted = chunks.slice(3).join(' ');
                                return <span key={`logline-${index}`} className="lh-lg">{formatted}</span>;
                              })
                            }
                          </pre>
                        </Card.Body>
                      </Card>
                    </>
                  ) : (
                    <Card className="py-3" bg="dark">
                      <Card.Body>
                        <Row>
                          <Col className="text-warning text-center">
                            <FontAwesomeIcon icon="info-circle" />
                            <span className="ms-2">{translate('noPodSelected')}</span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                }
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>
              <Card className="py-3" bg="dark">
                <Card.Body>
                  <Row>
                    <Col className="text-warning text-center">
                      <FontAwesomeIcon icon="info-circle" />
                      <span className="ms-2">{translate('noPodsFound')}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }
    </Container>
  );
};

export default LogsTab;
